<template>
    <div class="notice">
        <div class="title">
            <div class="sub-title">预约招聘会</div>
        </div>
        <el-card class="box-card" style="min-height: 100%">
            <Changecity @getList="handleAreaChange"></Changecity>
            <div class="notice-list" v-if="state" style="margin-top:15px;">
                <div v-if="jobFairList.length>0">
                    <div class="notice-list-item flex" v-for="item in jobFairList" :key="item.id">
                        <div class="item-pic">
                            <img :src="item.url" />
                        </div>
                        <div class="item-info flex">
                            <div class="info-name">{{ item.jobName }}</div>
                            <div class="info-company">已有<span class="warnning">{{ item.companiesNum }}</span>家企业参会，已有<span class="warnning">{{ item.positionNum }}</span>个职位，火热预约中……</div>
                            <div class="info-place">
                                <i class="el-icon-location iconstyle"></i>
                                <span>举办地点：{{ item.holdField }}</span>
                            </div>
                            <div class="info-place info-time">
                                <i class="el-icon-message-solid iconstyle"></i>
                                <span>举办时间：{{ item.holdStartTime }} 至 {{ item.holdEndTime }}</span>
                            </div>
                            <div class="info-place info-bus">
                                <i class="el-icon-info iconstyle"></i>
                                <span>公车路线：{{ item.trafficRoutes }}</span>
                            </div>
                        </div>
                        <div class="item-order">
                            <el-button type="primary" @click="goOrderBooth(item.id)">立即预约</el-button>
                        </div>
                        <div class="item-icon">
                            <img src="../../../assets/img/company/fair_3.png" alt="">
                            <!-- 未开始 -->
                            <!-- <img src="../../../assets/img/company/fair_1.png" alt="" v-if="item.status===5"> -->
                            <!-- 已开始 -->
                            <!-- <img src="../../../assets/img/company/fair_2.png" alt="" v-else-if="item.status===3"> -->
                            <!-- 进行中 -->
                            <!-- <img src="../../../assets/img/company/fair_3.png" alt="" v-else-if="item.status===1"> -->
                            <!-- 已结束 -->
                            <!-- <img src="../../../assets/img/company/fair_4.png" alt="" v-else-if="item.status===4"> -->
                            <!-- 取消 -->
                            <!-- <img src="../../../assets/img/company/fair_5.png" alt="" v-else> -->
                        </div>
                    </div>
                    <app-pagination @handleSizeChange="handleSize" @handleCurrentChange="handleCurrent" :total="total"></app-pagination>
                </div>
                <div class="no" v-else>
                    <img src="../../../assets/img/company/loading.png" alt="" srcset="" class="loading-img">
                    <p>正在加载中...</p>
                </div>
            </div>
            <div class="no-list flex" v-else>
                <img src="../../../assets/img/company/empty.png" alt="" srcset="">
                <p class="no-tips">亲爱的用户，目前还没有相关新的招聘会信息~~</p>
            </div>
        </el-card>
    </div>
</template>
<script>
import AppPagination from '../../../components/pagination/AppPagination'
import companyRequest from '../../../api/company'
import Changecity from '@/components/Changecity.vue'
export default {
    components: {
       AppPagination,
       Changecity,
    },
    mounted() {
        this.getJobFairList()
    },
    data() {
        return {
            state: true,
            queryForm : { 
                orgId:null,
                pageNo: 1,
                pageSize: 10
            },
            jobFairList: [],  //招聘会列表
            total: 0, //招聘会总个数
        }
    },
    methods: {
        handleAreaChange(data){
            this.queryForm.orgId=data
            this.queryForm.pageNo=1
            this.getJobFairList()
        },
        //获取招聘会
        getJobFairList() {
            companyRequest.getJobFairList(this.queryForm).then(res => {
                console.log(res)
                if (res.code === 200) {
                    this.jobFairList = res.data.list
                    this.total = res.data.total
                    if (this.jobFairList.length === 0) {
                        this.state = false
                    }else{
                        this.state=true
                    }
                    // console.log('jobFairList', this.jobFairList)
                }
            }).catch((err) => {
                this.state = false
            })
        },
        //跳转到预定展位页面
        goOrderBooth(rowId) {
            this.$router.push({
                path: '/company/orderBooth',
                query: {
                    id : rowId
                }
            })
        },
        handleSize(size) {
            this.queryForm.pageSize = size
            this.queryForm.pageNo = 1
            this.getJobFairList()
        },
        handleCurrent(num) {
            this.queryForm.pageNo = num
            this.getJobFairList()
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/css/global.scss';
.notice{
    height: 100%;
}
.notice-list{
    .notice-list-item{
        position: relative;
        // width: 80%;
        // min-width: 1240px;
        transition: all .3s;
        position: relative;
        padding: 20px;
        // border: 1px dotted #eee;
        border: 1px solid #eee;
        margin-bottom: 15px;
        margin-right: 20px;
        &:hover{
            box-shadow: 1px 3px 3px #f3f3f3;
            // transform: translateY(-3px);
        }
        .item-pic{
            width: 180px;
            height: 180px;
            margin-right: 40px;
            img{
                width: 180px;
                height: 180px;
            }
        }
        .item-info{
            // min-width: 70%;
            flex: 1;
            height: 180px;
            overflow: hidden;
            flex-direction: column;
            justify-content: space-between;
            .info-name{
                font-size: 16px;
                font-weight: 700;
                line-height: 40px;
            }
            .info-company{
                color: #666;
                font-size: 13px;
                .warnning{
                    color: $main;
                    font-weight: 700;
                }
            }
            .info-place{
                margin-top: 45px;
                font-size: 13px;
                color: #666;
            }
            .info-time{
                margin-top: 10px;
            }
            .info-bus{
                 margin-top: 10px;
            }
        }
        .item-order{
            width: 150px;
            // position: absolute;
            // top: 50%;
            // right: 2%;
            // transform: translate3d(0, -50%, 0);
            // margin-left: 150px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .el-button{
                width: 150px;
                letter-spacing: 2px;
                &:hover{
                    background: $hover;
                }
            }
        }
        .item-icon{
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}
.no-list{
    margin: 150px 0 200px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p{

    }

}
</style>